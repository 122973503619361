.custom-fs-11-600 {
  font-size: 11.2px;
  font-weight: 700;
}

.driiTa {
  box-shadow: none !important;
}

.driiTa::before {
  box-shadow: none !important;
}

.driiTa li a:hover,
.driiTa li button:hover,
.driiTa li a:focus,
.driiTa li button:focus {
  background-color: #099876 !important;
  color: #E1E1E1 !important;
}

.menu-circular-item:hover,
.menu-circular-item:focus {
  background-color: #099876 !important;
  color: #E1E1E1 !important;
}

.driiTa li button {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.driiTa li a:hover svg,
.driiTa li button:hover svg,
.driiTa li a:focus svg,
.driiTa li button:focus svg, .menu-circular-item:hover svg, .menu-circular-item:focus svg {
  fill: #E1E1E1 !important;
}

.driiTa li a:hover svg,
.driiTa li button:hover svg,
.driiTa li a:focus svg,
.driiTa li button:focus svg {
  fill: #E1E1E1 !important;
}

.menu-icon {
  fill: #099876;
  transition: fill 0.3s ease;
}

.menu-circuler-patient-btn-message {
  background: #E5E5E5;
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.custom-parent-table-container-message-tabs {
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000017;
  border-radius: 17px;
}

.container-header-message-list {
  display: flex;
  justify-content: space-between;

}

.bloc-search-message input {
  width: 100%;
  border: 0;
  padding: 20px 5px 20px 35px;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: #767278;
}

.bloc-search-message i {
  color: #7c7c7c;
}

.bloc-search-message button {
  background: transparent;
  position: absolute;
  left: 2%;
  top: 30%;
  border: none;
}

.bloc-search-message button:hover {
  background-color: #f45b64;
}

.bloc-search-message button:hover i {
  color: #fff;
}

.titre-messages-bloc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22.0454px;
  line-height: 33px;
  letter-spacing: 0.05em;
  color: #303030;
}

.empty-chat-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 350px;
}

.btn-send-newsletter {
  display: block;
  text-decoration: none;
  position: relative;
  transition: all 0.35s;
  border-radius: 4px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-left: 10px;
  letter-spacing: 2px;
}

.form-search-user {
  width: 100%;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: rgba(37, 39, 51, 0.8);
  padding: 15px 45px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-bottom: 1px solid #bdbdbd;
}

.user-for-send-message-infos h3 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.05em;
  color: #303030;
}

.user-for-send-message-infos h4 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #303030;
}

.img-profil-user-message {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.input-send-messge {
  border: none;
  background: #fff;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 165%;
  letter-spacing: 0.01em;
  color: #192a3e;
}

.image-profil-entete {
  width: 100%;
  height: auto;
  max-width: 60px;
  max-height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.img-expediteur {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.statut-expediteur {
  background: #2ed47a;
  border-radius: 50%;
  width: 6px;
  height: 6px;
}

.img-messge-contact {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.bloc-reponse {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2%;
}

.dropdown-menu.status-contact {
  left: -50px !important;
}

.list-group-item-meessage {
  padding: 20px 5px;
}

.dtails-messages-tabs-component {
  padding: 3%;
}

.dtails-messages-tabs-component .dropdown-toggle::after {
  display: none;
}

.dtails-messages-tabs-component .input-group {
  height: 80px;
  padding: 19px;
}

.dtails-messages-tabs-component .input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.dtails-messages-tabs-component .input-container :nth-child(1) {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dtails-messages-tabs-component .textarrea-message-custom-chat {
  border: 0;
  outline: 0;
  background-color: transparent;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #303030;
}

.dtails-messages-tabs-component .btn-container button {
  width: 60px;
  height: 60px;
  padding: 19px;
  border-radius: 20px;
  border: 0;
  outline: 0;
  cursor: pointer;
  background-color: #099876;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dtails-messages-tabs-component .left-footer-container {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #FAFAFB;
}

.dtails-messages-tabs-component .img-icon-chat {
  cursor: pointer;
}

.statut-message-tabs-non-lu {
  background: #f45b64;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 9px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.01em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statut-message-tabs-traite {
  color: #2ed47a;
}

.statut-message-tabs-non-traite {
  color: gray;
}

.statut-message-tabs-erreur {
  color: #ff3c5f;
}

.parent-table-container-message-tabs {
  height: 700px;
  overflow-y: scroll;
  padding: 15px;
}

.parent-table-container-message-tabs::-webkit-scrollbar {
  width: 4px;
}

.parent-table-container-message-tabs::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 10px;
}

.parent-table-container-message-tabs::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 3px solid transparent;
}

.btn-send-message-tabs {
  color: #099876;
}

.btn-send-message-tabs:hover {
  color: #099876;
}

.profil-detail-message {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #303030;
}

.time-detail-message {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.05em;
  color: #767278;
}

.conatiner-received-msg-item {
  display: flex;
  gap: 10px;
  align-items: baseline;
  margin-bottom: 2%;
}

.img-received-msg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.text-message-recu {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #303030;
  background: #E7E7E7;
  border-radius: 10px 10px 10px 0;
  padding: 10px;
  max-width: 50%;
}

.text-message-envoyer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #ffffff;
  background: #099876;
  border-radius: 10px 10px 0px 10px;
  padding: 10px;
  max-width: 50%;
  margin-left: auto;
}

.bloc-details {
  width: 100%;
  padding: 40px 20px;
  height: 90vh;
  overflow-y: scroll;
  /* min-height: 80vh; */
}

.bloc-details::-webkit-scrollbar {
  width: 4px;
}

.bloc-details::-webkit-scrollbar-track {
  background: #fff;
}

.bloc-details::-webkit-scrollbar-thumb {
  background-color: #099876;
  border-radius: 20px;
  border: 3px solid transparent;
}

.empty-chat-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 350px;
}

.bloc-profil-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nom-contact {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12.3359px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #303030;
}

.time-message-contact {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10.6675px;
  line-height: 13px;
  letter-spacing: 0.01em;
  color: #7c7c7c;
}

.contenu-message-contact {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12.6675px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #303030;
}

.bloc-message-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .msg-user-infos-container {
      padding-left: 5%;
  } */
.text-disconnect-time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #303030;
}

.container-display-input-message {
  width: 100%;
}

.textarrea-message-custom {
  background: transparent;
  width: 100%;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 15.8344px;
  line-height: 19px;
  letter-spacing: 0.05em;
}

.btn-filter-message {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #bdbdbd;
  border: none;
}

.btn-filter-message.active,
.btn-filter-message:hover {
  color: #000000 !important;
  border: none;
}

.container-btn-filter-message {
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-btn-chat-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.list-group-item-meessage.active {
  background-color: #4c6fff0f;
  border-radius: 10px;
  border-bottom: none;
  border-color: #4c6fff0f;
}

button.btn.btn.btn-see-more.notif-enabled {
  color: #e82222;
}

button.btn.btn.btn-see-more.notif-disabled {
  color: #1caa82;
}

.btn-nav-message {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: #BDBDBD;
}

.btn-nav-message.active {
  color: #000000;
  border: 0;
}

.container-btn-nav-message {
  display: flex;
  gap: 15px;
  align-items: center;
}

.user-for-send-message {
  list-style: none;
}

.see-more-btn {
  background: transparent;
  border: 1px solid #099876;
  font-size: 15px;
  color: #099876;
  border-radius: 10px;
  padding: 0.3rem;
}

.see-more-btn:is(:hover, :focus-visible) {
  background: #099876;
  color: #fff;
}

.details-message-item {
  padding: 1rem;
}

.no-view-desktop .details-message-item {
  padding: 0px !important;
}

.react-loading-skeleton.text-message-envoyer {
  background: #ebebeb !important;
}
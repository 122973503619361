.page-content-base-container {
  transition: 0.75s;
  overflow: auto;
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
}

.user-dash-page-content-container {
  background: #ffffff;
  padding: 20px 34px;
}

.item-stat-dashboad-view {
  padding: 10px 20px;
  width: 100%;
  position: relative;
  align-items: center;
  box-shadow: 0px 12px 26px 0px #101E730F;
  border-radius: 10px;
  background: #fff;
}

.titre-item-stat-dashboad-view {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  color: #25282B;
}

.chiffre-item-stat-dashboad-view {
  font-family: "Lato";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  color: #099876;
}

.content-text-item-stat-dashboad-view {
  width: 100%;
}

.admin-page-container-layout {
  margin-top: 90px;
}

.content-stat-dashboard-view {
  display: flex;
  align-items: center;
  gap: 30px;
}

.content-chart-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-control-select-date-graph {
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
  border-radius: 100px;
  /* background: url(../../../assets/icons/Shape.png) 96% 50% / 13px no-repeat
    #fff; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: #252733;
  max-width: 300px;
  padding: 15px;
  border: 0;
}

.chart-item-admin-title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #000000;
}

.title-content-dash-admin {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
}

.container-title-content-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-content-dash-admin {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
}

.content-graph-admin {
  background: #fff;
  padding: 20px;
  box-shadow: 0px 12px 26px 0px #101E730F;
  border-radius: 10px;
  position: relative;
}

.text-subtitle-graph {
  font-family: "Lato";
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.10000000149011612px;
  color: #A0A4A8;
}

.filter-container {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.fc-timeGridWeek-button.btn-type-date {
  border-radius: 20px 0px 0px 20px !important;
}

.btn-type-date {
  padding: 10px 15px;
  box-shadow: 0px 1.98221px 9.91103px rgba(0, 0, 0, 0.07);
  font-size: 13px !important;
}

.fc-timeGridDay-button.btn-type-date {
  border-radius: 0px 20px 20px 0px !important;
}
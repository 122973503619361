:root {
    --menu-wh: 30rem;
    --menu-item: var(--menu-wh) / 2;
}


.filtered-and-search-container label {
    color: rgba(0, 0, 0, 0.60);
    text-align: center;
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.filtered-and-search-container input,
.select-status-filter {
    min-width: 25%;
    border: none;
    padding: 14px 12px 12px;
    border-radius: 10px;
    background: #F7F7F7;
    outline: none;
    color: var(--grayscale-black, #252733);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
}

.light-card {
    padding: 2rem 1rem;
    background: #F6F8FB;
    border-radius: 1rem;
}

.light-card .custom-card-header {
    position: relative;
}

.light-card .custom-card-header .custom-title {
    color: var(--primary-default, #099876);
    font-family: Lato;
    font-style: normal;
    line-height: 24.461px;
    letter-spacing: 0.188px;
}

.light-card .custom-card-header .icon,
.light-card .instruction-content .icon {
    color: var(--primary-default, #099876);
    font-size: 20px;
}

.light-card .instruction-content .icon {
    font-size: 17px;
}

.light-card .custom-card-header .custom-title .demand {
    font-size: 22.58px;
    font-weight: 700;
}

.light-card .custom-card-header .custom-title .description {
    font-size: 9.408px;
    font-weight: 500;
}

.light-card .card-separator {
    width: 100%;
    height: 1px;
    opacity: 0.5;
    background: var(--neutral-divider, #E8E8E8);
}

.light-card .custom-card-content {
    color: var(--neutral-gray-dark, #52575C);
    font-family: Lato;
    font-size: 16.816px;
    font-style: normal;
    line-height: 26px;
    position: relative;
    padding: 2rem 0 1rem;
}

.light-card .custom-card-content .cs-title {
    font-weight: 400;
    letter-spacing: 0.2px;
}

.light-card .custom-card-content .cs-value {
    font-weight: 700;
    letter-spacing: 0.188px;
}

.light-card .custom-card-header .cancel-rdv-content,
.light-card .custom-card-content .get-location-rdv-content {
    position: absolute;
    top: 0;
    right: 0;
}



.light-card .custom-card-header .cancel-rdv-content,
.delete-ordonnance-btn {
    color: var(--red-dark, #E74F48);
}


.light-card .custom-card-header .cancel-rdv-content:hover,
.delete-ordonnance-btn:hover {
    border-color: var(--red-dark, #E74F48);
}

.light-card .custom-card-content .get-location-rdv-content {
    color: var(--primary-default, #099876);
    top: 2rem;
}

.light-card .custom-card-content .get-location-rdv-content:hover {
    border-color: var(--primary-default, #099876);
}

.light-card button {
    border: 1.4px solid transparent;
    padding: 8px 14px;
    border-radius: 10px;
    background: transparent;
}

.instruction-content {
    padding: 2.5rem 1rem 0;
}

.instruction-content .title {
    color: var(--primary-default, #099876);
    font-family: Lato;
    font-size: 22.58px;
    font-style: normal;
    font-weight: 700;
    line-height: 24.461px;
    letter-spacing: 0.188px;
}

.instruction-content .intructions-list,
.intructions-list p,
.intructions-list h3,
.intructions-list span,
.intructions-list ol,
.intructions-list ul,
.intructions-list pre,
.intructions-list blockquote {
    color: var(--neutral-gray-dark, #52575C);
    font-family: Lato;
    font-size: 18.816px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.461px;
    letter-spacing: 0.188px;
}

.profil-image-container {
    padding: 2.4rem 0;
    display: grid;
    place-items: center;

    & img {
        width: 150px;
        height: auto;
        border-radius: 50%;
    }
}

.complete-profil-btn {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    color: var(--primary-default, #099876);
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: inline-flex;
    padding: 13px 49px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.30);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}


.file-name-colum {
    color: #099876;
}

.file-name-colum:hover {
    text-decoration: underline;
}
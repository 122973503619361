.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-timeGridDay-button {
    border: 1px solid #099876 !important;

    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 11.8635px !important;
    line-height: 16px !important;
    text-align: center !important;
    letter-spacing: 0.338957px !important;
    color: #099876 !important;
    background: #fff !important;
}

.fc .fc-button-primary:focus,
.fc .fc-button:focus {
    box-shadow: none !important;
}

.fc-button-active {
    background: #dbf0eb !important;
    border: 1px solid #099876 !important;
    /* border-radius: 5.08436px 0px 0px 5.08436px !important; */

    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 11.8635px !important;
    line-height: 16px !important;
    text-align: center !important;
    letter-spacing: 0.338957px !important;
    color: #099876 !important;
}

.available {
    border-color: #099876 !important;
}

.available .event-time, .available .calendar-event-title {
    color: #099876 !important;
}


.no-available .event-time, .no-available .calendar-event-title {
    color: red !important;
}

.fc-toolbar-chunk {
    display: flex !important;
    align-items: center !important;
}

.fc-toolbar-title {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15.2531px !important;
    line-height: 21px !important;
    color: #5e5873 !important;
}

.fc-next-button,
.fc-prev-button {
    border: none !important;
    background: none !important;
    color: #6c757d !important;
    font-size: 14px !important;
}

.fc-scrollgrid-section th {
    background: #ffffff !important;
    border-radius: 0px 5.08436px 5.08436px 0px !important;
}

.fc-scrollgrid-section-liquid td {
    background: #ffffff;
}

.fc-day-today {
    background: #f8f8f8 !important;
}

.fc-popover-header {
    background: #ffffff !important;

    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 11.8635px !important;
    line-height: 19px !important;
    color: #5e5873 !important;
}

.fc-more-popover {
    border: 1px solid #ebe9f1 !important;
}

.fc-daygrid-day-top a {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 11.8635px !important;
    line-height: 18px !important;
    color: #6e6b7b !important;
    text-decoration: none !important;
}

.fc-scrollgrid-sync-inner a {
    font-style: normal;
    font-weight: 500;
    font-size: 11.8635px;
    line-height: 18px;
    text-align: center;
    color: #6e6b7b;
    text-decoration: none !important;
}

.fc-col-header-cell {
    border-top: 1px solid #e9eaec !important;
    border-bottom: 1px solid #e9eaec !important;
    border-left: 0 !important;
    border-right: 0 !important;
}

.fc-scrollgrid-section th {
    padding: 0px !important;
}

.fc-scrollgrid-section th {
    padding: 0px !important;
}

.fc-scrollgrid-sync-inner {
    padding: 12px 0px !important;
}

.fc-scrollgrid-section-body td {
    padding: 0px !important;
}

.demo-app {
    display: flex;
    gap: 15px;
}

.demo-app-sidebar {
    width: 20%;
    background: #ffffff;
    border-radius: 5px 0px 0px 5px !important;
    border: 1px solid #ebe9f1;
    padding: 15px;
}

.demo-app-main {
    width: 80%;
    background: #fff;
}

.custom-width-100 {
    width: 100%;
}

.demo-app-sidebar-section-calendar-btn-add {
    background: #099876;
    border-radius: 5px;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.338957px;
    color: #ffffff;
    padding: 10px 7px;
    width: 100%;
}

.demo-app-sidebar-section-btn-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.calendar-agenda-filter-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.338957px;
    color: #5e5873;
}

.demo-app-sidebar-section label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.338957px;
    color: #5e5873;
    display: flex;
    gap: 8px;
    align-items: center;
}

.demo-app-sidebar-section {
    margin-bottom: 10px;
}

.fc-toolbar-ltr {
    padding: 20px 10px !important;
    margin-bottom: 0px !important;
}

@media (max-width: 767px) {
    .demo-app {
        display: flex;
        flex-direction: column;
    }

    .demo-app-sidebar {
        width: 100%;
    }

    .demo-app-main {
        width: 100%;
    }
}

.search-form-content-agenda label {
    position: absolute;
    top: 5px;
    right: 4%;
    color: #647891;
    font-size: 15px;
}

input.form-control.search-input-agenda {
    padding: 10px 1rem !important;
    border: none;
    color: #868e96;
    border: 0;
    background: #f5f5f7;
    border-radius: 8px;

    font-style: normal;
    font-weight: 500;
    font-size: 10.1687px;
    line-height: 20px;
    letter-spacing: 0.508436px;
}

input.form-control.search-input-agenda::placeholder {
    color: #b9b9c3;
}

.search-form-content-agenda svg.search-icony {
    width: 20px;
    height: 20px;
}

.custom-render-event-content {
    display: flex;
    gap: 0px;
    font-size: 10px;
    align-items: flex-start;
    margin-bottom: 5px;
    flex-direction: column;
    overflow: auto;
}

.fc-theme-standard .fc-scrollgrid {
    border: 0 !important;
}

.app-custom-check-container {
    margin-bottom: 15px;
}

.app-custom-check-container label {
    transition: 0.3s;
}

.app-custom-check-container .custom-checkbox {
    position: absolute !important;
    z-index: -1 !important;
    opacity: 0 !important;
}

.app-custom-check-container .custom-checkbox+label {
    display: inline-flex !important;
    align-items: center !important;
    user-select: none !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #555555 !important;
}

.app-custom-check-container .custom-checkbox+label::before {
    transition: 0.3s !important;
    content: "" !important;
    display: inline-block !important;
    width: 20px !important;
    height: 20px !important;
    flex-shrink: 0 !important;
    flex-grow: 0 !important;
    border: 1px solid #666666 !important;
    border-radius: 4px !important;
    margin-right: 0.5em;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: 50% 50% !important;
}

.app-custom-check-container .custom-checkbox:checked+label::before {
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2210%22%20viewBox%3D%220%200%2012%2010%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M10%202L4.5%208L2%205.27273%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22square%22%2F%3E%0A%3C%2Fsvg%3E%0A") !important;
}

.custom-checkbox:checked+label::before {
    border-color: #099876 !important;
    background-color: #099876 !important;
}

.suivi .custom-checkbox:checked+label::before {
    border-color: #e06c7d;
    background-color: #e06c7d;
}

.ordonnance .custom-checkbox:checked+label::before {
    border-color: #30d9c4;
    background-color: #30d9c4;
}

.urgence .custom-checkbox:checked+label::before {
    border-color: #eb5757;
    background-color: #eb5757;
}

.vaccination .custom-checkbox:checked+label::before {
    border-color: #cbfa63;
    background-color: #cbfa63;
}

.autre .custom-checkbox:checked+label::before {
    border-color: #ebe9df;
    background-color: #ebe9df;
}

/* Agenda css */

.container-title-page-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.breadcrumb-container {
    display: flex;
    gap: 10px;
    align-items: center;
}

.breadcrumb-navlink {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #8c8ca2;
    text-decoration: none;
}

.breadcrumb-navlink:hover {
    color: #099876;
    font-weight: 600;
}

.breadcrumb-current-page {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #4a4a68;
    margin-bottom: 0;
}

.title-page-head {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #000000;

    font-family: "Roboto";
    opacity: 0.7;
}

.btn-theme-head-page {
    background: #099876;
    border-radius: 10px;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #ffffff;
    padding: 15px;
}

/* =============================== AGENDA SAP ===================== */

.checkbox-add-event input {
    cursor: pointer;
    user-select: none;
    display: block;
}

.checkbox-add-event {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.checkbox-add-event label span {
    position: relative;
    transition: 0.4s;
    padding: 0 20px;
    height: 46px;
    display: flex;
    align-items: center;
    column-gap: 7px;
    border-radius: 20px;
    box-sizing: border-box;

    background: #e0e0e0;
    box-shadow: 2px 9px 13px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    border: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #000000;
}

.checkbox-add-event label span .icon {
    font-size: 1.1em;
}

.checkbox-add-event label input {
    pointer-events: none;
    display: none;
}

.checkbox-add-event label input:checked+span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #000000;
    background: #dbf0eb;
    box-shadow: 2px 9px 13px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    border: none;
}

.container-checkbox {
    padding: 15px 0;
    border-radius: 10px;
    display: flex;
    gap: 35px;
    flex-direction: column;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000 !important;
}

.carousel.carousel-slider .control-arrow {
    top: -972px !important;
    color: #000 !important;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #000 !important;
}

.carousel .carousel-status {
    display: none;
}

.carousel .control-dots {
    display: none;
}

.carousel .slide .legend {
    position: inherit !important;
    opacity: inherit !important;
    margin-left: inherit !important;
    background: transparent;
    bottom: inherit !important;
    left: inherit !important;

    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    color: rgba(0, 0, 0, 0.8) !important;
}

.text-legend-bold {
    font-weight: 800;
    /* font-weight: bold; */
}

.position-content-carousel {
    position: absolute;
    top: 100%;
}

.carousel .slider-wrapper {
    height: 1000px;
}

.container-checkbox-carousel {
    padding: 15px 0;
    border-radius: 10px;
    display: flex;
    grid-gap: 35px;
    gap: 10px;
    flex-direction: column;
}

.checkbox-carousel label {
    cursor: pointer;
    user-select: none;
    display: block;
}

.checkbox-carousel label span {
    position: relative;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    transition: 0.4s;
    padding: 0 35px;
    height: 46px;
    color: #414141;
    display: flex;
    align-items: center;
    column-gap: 7px;
    background: #cccccc;
    border-radius: 20px;
}

.checkbox-carousel label span .icon {
    font-size: 1.1em;
}

.checkbox-carousel label input {
    pointer-events: none;
    display: none;
}

.custom-agenda-controler-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 25px;

    height: 40px;
    width: 60%;
    margin: auto;
    background: rgba(219, 221, 222, 0.4);
    border-radius: 10px;
}

.label-form-container p {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;

    color: rgba(0, 0, 0, 0.7) !important;
}

.label-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-text-legend-container p {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    color: rgba(0, 0, 0, 0.8);
}

/* =============================== AGENDA SAP ===================== */
.event-title {
    color: #333;
    font-size: 15px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.event-time {
    font-size: 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.calendar-event-title {
    font-size: 11px;
    line-height: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.popup-links li {
    list-style: none;
}

.popup-link,
.popup-btn-del {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #4a4a68;
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    transition: all ease 300ms;
    white-space: nowrap;
}

.popup-link svg {
    font-size: 18px;
}

.popup-link:hover {
    text-decoration: underline;
    color: #4a4a68;
    font-weight: 500;
}

.popup-btn-del {
    background-color: transparent;
    border: none;
    padding: 0;
}

.popup-btn-del:hover {
    font-weight: 500;
}

.container-info-detail-praticien-cabinet {
    display: flex;
    justify-content: space-between;
}

.content-cabinet-praticien-detail-praticien {
    display: flex;
    align-items: center;
    gap: 40px;
}

.img-praticien-cabinet {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
}

.container-info-detail-praticien-cabinet .btn-action-icon-edit,
.container-info-detail-praticien-cabinet .btn-action-icon-edit:hover {
    padding: 0 !important;
    background: #a0a4a82c;
    border-radius: 50%;
}

.container-info-detail-praticien-cabinet .btn-action-icon-delete,
.container-info-detail-praticien-cabinet .btn-action-icon-delete:hover {
    padding: 0 !important;
    background: #e53e3e27 !important;
    border-radius: 50%;
}

.rdv-cs-title {
    font-family: "Lato";
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    color: #4B4B4B;
}

.rdv-cs-value {
    font-family: "Lato";
    font-size: 27px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    color: #4B4B4B;
}

.fc-timegrid-event {
    border: 2px solid #099876;
    background: #dbf0eb59;
}
.custom-sm-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.custom-sm-top-title {
    color: var(--neutral-black, #25282B);
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 02px;
}

.custom-sm-top-subtitle {
    color: var(--neutral-black, #25282B);
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 02px;
}

.custom-add-item-btn {
    display: inline-flex;
    padding: 13.038px 20px;
    justify-content: center;
    align-items: center;
    gap: 10.029px;
    border-radius: 10.029px;
    border: 1px solid var(--primary-dark, #099876);
    background: #fff;
    transition: background .3s ease;
    color: var(--neutral-black, #25282B);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
}

.custom-add-item-btn:hover {
    background: var(--primary-dark, #099876);
    color: #fff;
}

.custom-add-item-btn span.add-icon {
    font-size: 18px;
    color: var(--primary-dark, #099876);
}

.custom-add-item-btn:hover span.add-icon {
    color: #fff;
}

.filtered-rdv-on-date-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
}

.filtered-rdv-on-date-container .filter-scope-btn {
    width: 36px;
    height: 36px;
    background: transparent;
    border-radius: 50%;
    border: 0.7px solid var(--gray-400, #DCDFE3);
    color: rgba(102, 110, 125, 1);
    font-weight: 700;
    transition: border-color background .2s ease;
}

.filtered-rdv-on-date-container .filter-scope-btn:hover {
    border-color: var(--primary-default, #099876);
    color: #fff;
    background: var(--primary-default, #099876);
}


/* tables -- start */
.custom-table tbody tr td {
    /* text-align: center; */
}

/* tables -- end */


.search-patient-box input[type='search'] {
    width: 50%;
    padding: 14px 0.5rem;
    border-radius: 100px;
    background: var(--neutral-white, #FFF);
    color: var(--neutral-gray, #A0A4A8);
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.1px;
    outline: none;
    border: 1.2px solid var(--primary-default, #099876);
}

.search-patient-box .search-submit-patient-btn {
    display: inline-flex;
    padding: 10px 21px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: var(--primary-default, #099876);
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: 1.2px solid var(--primary-default, #099876);
    transition: .3s ease;
}

.search-patient-box .search-submit-patient-btn:hover {
    background: transparent;
    color: var(--primary-default, #099876);
}

.custom-sm-top .add-mode-paiment-btn {
    border: 1.2px solid transparent;
    background: transparent;
    color: var(--primary-default, #099876);
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: .2s ease;
}

.paiment-method-card {
    padding: 0.6rem;
    border-radius: 10px;
    background: #F6F8FB;
    color: var(--neutral-black, #25282B);
    font-family: Lato;
    font-size: 10px;
    font-style: normal;
    line-height: 26px;
    letter-spacing: 0.2px;
    position: relative;
}

.paiment-method-card .sm-title {
    font-weight: 300;
}

.paiment-method-card .sm-value {
    font-weight: 700;
}

.paiment-method-card .change-card-action-btn {
    border: 1.2px solid transparent;
    outline: none;
    background: transparent;
    color: var(--primary-default, #099876);
    text-align: center;
    font-family: Poppins;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
    border-radius: 10px;
    transition: .2s ease;
}

.custom-sm-top .add-mode-paiment-btn:is(:hover, :focus),
.paiment-method-card .change-card-action-btn:is(:hover, :focus) {
    border-color: var(--primary-default, #099876);
}

.tabs-title-content,
.param-tabs-title-content {
    display: flex;
    list-style: none;
    gap: 4rem;
    padding-left: 0;
}

.tabs-title-content {
    border-bottom: 1px solid var(--icon, #DBDDE0);
}

.tabs-title-content .tab-item,
.param-tabs-title-content .tab-item {
    color: var(--neutral-gray, #A0A4A8);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.2px;
    position: relative;
    padding: 0.5rem 0.7rem;
    display: grid;
    place-items: center;
}

.tabs-title-content .tab-item-active,
.param-tabs-title-content .tab-item-active {
    color: var(--primary-default, #099876);
    font-weight: 700;
}

.tabs-title-content .tab-item-active:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 70px;
    height: 2px;
    background: var(--primary-default, #099876);
    margin: 0 auto;
}

.param-tabs-title-content {
    align-items: center;
    justify-content: flex-start;
    background: #F6F8FB;
    height: 50px;
}

.param-tabs-title-content .tab-item-active {
    background: #fff;
}

.param-tabs-title-content .tab-item {
    height: 100%;
}

.custom-sm-pass-title {
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
}

.medecin-info-content .cover-container {
    display: grid;
    place-items: center;
    padding: 2.5rem 0 4rem;
}

.medecin-info-content .cover-container img {
    width: 160px;
    height: auto;
    object-fit: cover;
}

.medecin-info-content .row-infos-medecin {
    padding: 0 1.4rem;
}

.row-paiement-details,
.show-paiement-modal-title {
    color: #303030;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
    row-gap: 10px;
}

.row-paiement-details .sm-title {
    font-size: 16px;
    font-weight: 400;
}

.row-paiement-details .sm-value,
.show-paiement-modal-title {
    font-size: 20px;
    font-weight: 600;
}

.sm-value p {
    font-size: 15px;
    font-weight: 400;
    color: #303030;
}

.custom-modal-title {
    color: #303030;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
}

.close-details-paiment-modal {
    border: none;
    background: transparent;
    font-size: 19px;
}

.patient-side-bar {
    padding: 1rem 1rem 4rem;
    background: #fff;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
    min-height: 100vh;
    height: 100%;
}

.patient-side-bar .patient-image-and-infos-container img {
    width: 100px;
    height: auto;
    object-fit: cover;
}

.patient-side-bar .patient-image-and-infos-container {
    color: var(--neutral-black, #25282B);
    font-family: Lato;
    font-style: normal;
    line-height: 26px;
    letter-spacing: 0.2px;
}

.patient-side-bar .patient-name {
    font-size: 18px;
    font-weight: 700;
}

.patient-side-bar .patient-other-infos {
    font-size: 14px;
    font-weight: 400;
}

.patient-side-bar-item {
    border-radius: 5.167px;
    background: #fff;
    box-shadow: 0px 0.8917500376701355px 10.701000213623047px 0px rgba(0, 0, 0, 0.08);
    padding: 0.6rem 1rem;
    font-family: Lato;
    font-style: normal;
    line-height: normal;
    font-style: normal;
    border: 2px solid transparent;
    transition: border-color .3s ease;
    cursor: pointer;
}

.patient-side-bar-item.it0:hover {
    border-color: #F45B5B;
}

.patient-side-bar-item.it1:hover {
    border-color: #1678F2;
}

.patient-side-bar-item.it2:hover {
    border-color: #F2B416;
}

.patient-side-bar-item.it3:hover {
    border-color: #AC82FF;
}

.patient-side-bar-item.it4:hover {
    border-color: #02A69C;
}

.patient-side-bar-item.it5:hover {
    border-color: #FF7C7C;
}

.patient-side-bar-item .item-title {
    color: #000;
    font-size: 7.234px;
    font-weight: 600;
    text-transform: uppercase;
}

.patient-side-bar-item .item-title img {
    width: 20px;
    height: 20px;
    object-fit: cover;
}

.patient-side-bar-item .item-value {
    text-align: right;
    font-size: 17.567px;
    font-weight: 700;
    border: none;
}

.patient-side-bar-item .item-value.color-red {
    color: #F45B5B;
}

.patient-side-bar-item .item-value.color-blu {
    color: #1678F2;
}

.patient-side-bar-item .item-value.color-oran {
    color: #F2B416;
}

.patient-side-bar-item .item-value.color-vio {
    color: #AC82FF;
}

.patient-side-bar-item .item-value.color-gre {
    color: #02A69C;
}

.patient-side-bar-item .item-value.color-pin {
    color: #FF7C7C;
}

.patient-side-bar-item .item-value.color-green {
    color: #A9AF3C;
}


.patient-detail-tabs-content .detail-patient-tabs {
    gap: 1rem;
}

.patient-detail-content {
    padding: 2rem 1rem 6rem;
    background: #F6F8FB;
    border-radius: 0 1rem 1rem 0;
}

.folder-tab-top-head h3,
.all-files-container .file-item-card span {
    color: #323C47;
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

}

.folder-tab-top-head h3 {
    font-size: 11px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
}

.folder-tab-top-head .join-file-btn {
    border: 1.6px solid transparent;
    border-radius: 14px;
    transition: border-color .3s ease;
    color: var(--neutral-black, #25282B);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
}

.folder-tab-top-head .join-file-btn:is(:hover, :focus) {
    border-color: var(--primary-default, #099876);
}

.folder-tab-top-head .join-file-btn .attach-icon {
    width: 24px;
    height: 24px;
    transform: rotate(-90deg);
    color: var(--primary-default, #099876);
    margin-right: 5px;
}

.results-btn .join-file-btn {
    border: 1.6px solid transparent;
    border-radius: 14px;
    transition: border-color .3s ease;
    color: var(--neutral-black, #25282B);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
}

.results-btn .join-file-btn .attach-icon {
    width: 15px;
    height: 15px;
    transform: rotate(-90deg);
    color: var(--primary-default, #099876);
    margin-right: 5px;
}

.attach-icon-antecedent {
    width: 15px;
    height: 15px;
    transform: none !important;
    color: var(--primary-default, #099876);
    margin-right: 5px;
}

.results-btn .join-file-btn .attach-icon-joint {
    width: 15px;
    height: 15px;
    /* transform: rotate(-90deg); */
    color: var(--primary-default, #099876);
    margin-right: 5px;
}

.all-files-container .file-item-card {
    padding: 1.2rem 1.7rem;
    border: 2px solid transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.all-files-container .file-item-card span {
    display: inline-flex;
    font-size: 12px;
    letter-spacing: 0.109px;
}

.all-files-container .file-item-card:hover {
    border-color: #278CFD;
}

.ajust-content {
    margin-left: -1.4rem;
    margin-right: -1rem;
}

.status-formatter-container .on-going-color {
    color: #CE7F09;
    font-weight: 600;
}

.status-formatter-container .finished-color {
    color: var(--primary-dark, #099876);
    font-weight: 600;
}

.add-other-btn {
    width: 100%;
    background: transparent;
    border: none;
    color: #555;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-flex;
    align-items: center;
}

.add-other-btn .add-icon {
    font-size: 18px;
}

.add-other-btn:hover {
    background: rgba(230, 243, 240, 0.50);
    color: #555;
}

.modify-password-btn {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    color: var(--black, #0C0B0B);
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    border: 1.3px solid transparent;
    background: transparent;
    padding: 8px 14px;
}

.modify-password-btn:hover {
    border-color: var(--primary-dark, #099876);
}

.modify-password-btn .icon {
    color: var(--primary-dark, #099876);
}

.custom-table-row-class {
    cursor: pointer;
}

.custom-close-modal-btn {
    border: none;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: transparent;
}

.custom-close-modal-btn:hover {
    background: #efefef;
}

.add-notes-btn {
    display: flex;
    padding: 8px 18px;
    padding: 7.04px 20px;
    justify-content: center;
    align-items: center;
    gap: 10.029px;
    border-radius: 10.029px;
    background: #fff;
    color: var(--neutral-black, #25282B);
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    border: 1.3px solid transparent;
    transition: .3s ease;
}

.add-notes-btn:hover {
    border-color: #099876;
    color: #099876;
}

.add-notes-btn .add-icon {
    color: #099876;
    font-size: 18px;
}

.btn-ordonnance-modal {
    background-color: #fff;
    box-shadow: 0px 4px 13px 0px #0000001A;
    padding: 20px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.btn-ordonnance-modal p {
    font-family: Lato;
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0px;
    color: #1B1B1B;
}

.btn-ordonnance-modal:hover p {
    color: #099876;
}

.search-patient-box .react-datepicker__input-container {
    width: 50%;
    background: var(--neutral-white, #FFF);
    color: var(--neutral-gray, #A0A4A8);
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.1px;
    outline: none;
    border: 1.2px solid var(--primary-default, #099876);
    border-radius: 100px;
    padding: 7px 10px;
}

.search-patient-box .react-datepicker__input-container .input-date-naissance {
    padding: 7px 0.5rem;
    border: none;
    width: 100%;
}

.accordion-history .accordion-button {
    color: #099876 !important;
    background-color: #09987624 !important;
    font-weight: 500;
}

.accordion-history .accordion-button::after {
    background-image: none !important;
}

.accordion-history .cs-title {
    font-weight: 500;
}

 
.hitory-body p,
.hitory-body h3,
.hitory-body span,
.hitory-body ol,
.hitory-body ul,
.hitory-body pre,
.hitory-body blockquote {
    color: #212529;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.461px;
    letter-spacing: 0.188px;
}
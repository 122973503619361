.admin-table thead tr > th:last-child {
    text-align: center;
}

.profil-status-formatter {
    color: #779DC0;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
}

.patient-profil-table thead {
    border-width: 0 0 0 0;
    border-color: #F6F8FB;
}
.patient-profil-table thead th, .patient-profil-table tbody tr td {
    color: var(--neutral-black, #25282B);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    text-align: center;
}

.patient-profil-table thead th {
    background: #F6F8FB;;
    font-weight: 600;
    text-transform: uppercase;
    padding: 6px 0;
}

.patient-profil-table tbody tr {
    background: #fff;
}

.patient-profil-table tbody tr td {
    border-width: 10px 0 10px 0;
    font-weight: 400;
}

.patient-profil-table tbody tr {
    border-color: #F6F8FB;
    padding: 28px 0 8px;
}

/* customize tables -- end */
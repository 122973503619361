.admin-table thead tr > th:last-child {
    text-align: center;
}

.admin-table tbody tr td {
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* customize tables -- start */
.medecin-patients-detail-table thead {
    border-width: 0 0 1rem 0;
    border-color: #F6F8FB;
}
.medecin-patients-detail-table thead th, .medecin-patients-detail-table tbody tr td {
    color: var(--neutral-black, #25282B);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    /* text-align: center; */
    vertical-align: middle;
}

.medecin-patients-detail-table thead th {
    background: #F6F8FB;;
    font-weight: 600;
    text-transform: uppercase;
    padding: 18px 0;
}

.medecin-patients-detail-table tbody tr {
    background: #fff;
}

.medecin-patients-detail-table tbody tr td {
    border-width: 10px 0 10px 0;
    font-weight: 400;
}

.medecin-patients-detail-table tbody tr {
    border-color: #F6F8FB;
    padding: 28px 0 8px;cursor: pointer;

}

/* customize tables -- end */

.timer-icon {
    font-size: 17px;
    color: var(--primary-default, #099876);
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}
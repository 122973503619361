.content-format-statut-table {
    font-family: "Lato";
font-size: 14px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
color: #099876;
}
.btn-theme-admin {
    background: #099876;
    border-radius: 10px;
    padding: 15px;
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0px;
    color: #fff;
}
.btn-theme-admin:hover {
    color: #099876;
    border: 1px solid #099876;
    background: #fff;
}
.empty-header {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  padding: 50px;
}
.empty-header-auth {
  background-color: #fff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.04), 0px 4px 56px #0000000a;
  z-index: 1;
  position: relative;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-right-side-container {
  padding: 100px 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-auth-form-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #1C1C1C;
}

.auth-form-control {
  background: #E6F3F0;
  border-radius: 5px;
  padding: 15px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #444150;
  border: 0;
}

.auth-form-control:focus {
  box-shadow: none;
  border: 1px solid #099876;
}

.form-group.auth-form-group {
  margin-bottom: 40px;
}

.content-forget-password-link {
  display: flex;
  justify-content: flex-end;
}

.forget-password-link {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #099876;
  text-decoration: none;
}

.forget-password-link:hover {
  text-decoration: underline;
  color: #099876;
  font-weight: bold;
}

.auth-form-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(15, 32, 52, 0.4);
}

.auth-submit-btn, .light-card .auth-submit-btn {
  background: #099876;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
}

.auth-submit-btn:hover {
  background: #fff;
  color: #099876;
  border: 1px solid #099876;
  text-decoration: none;
}

.auth-row.row .auth-col {
  padding: 0 !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.form-label-auth-login {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #444150;
  display: flex;
}

.auth-form-message {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0f2034;
}

.show-hide-password {
  position: absolute;
  right: 2%;
  color: #099876;
  top: 43px;
  font-size: 20px;
}

.text-message-forgepassword {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
}
.form-label-auth-login {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #555555;
  display: flex;
}
.container-beetwen-connect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.text-beetwen-connect {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  color: #1C1C1C;
}
.line-hr-connect {
  width: 50%;
}
.auth-submit-social-media {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  color: #1C1C1C;
  border: 1px solid #EEEEEE;
  border-radius: 200px;
  padding: 15px;
  width: 100%;
}
.auth-submit-social-media:hover {
  background: #099876;
  color: #fff;
}
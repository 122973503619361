.custom-agenda-controler-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 25px;
  height: 40px;
  width: max-content;
  margin: auto;
  border-radius: 10px;
}

.custom-text-legend-container p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  color: rgba(0, 0, 0, 0.8);
}

.text-legend-bold {
  font-weight: 800;
  /* font-weight: bold; */
  text-transform: capitalize;
}

.checkbox-add-event input {
  cursor: pointer;
  user-select: none;
  display: block;
}

.checkbox-add-event {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}

.add-availity.checkbox-add-event {
  justify-content: flex-start !important;
}

.checkbox-add-event label span {
  position: relative;
  border: 1px solid #737272;
  border-radius: 10px;
  transition: 0.4s;
  padding: 0 35px;
  height: 46px;
  color: #414141;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 7px;
  background: #cccccc;
  border-radius: 20px;
  background: #cccccc;
  box-sizing: border-box;
}

.add-availity.checkbox-add-event label span {
  padding: 0 20px !important;
}

.checkbox-add-event label span .icon {
  font-size: 1.1em;
}

.checkbox-add-event label input {
  pointer-events: none;
  display: none;
}

.checkbox-add-event label input:checked+span {
  border: 1px solid #19d0a5 !important;
  color: #19d0a5;
  background: #19d0a526;
  border-radius: 20px;
  font-weight: 900 !important;
}

.container-checkbox {
  padding: 15px 0;
  border-radius: 10px;
  display: flex;
  gap: 35px;
  flex-direction: column;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000 !important;
}

.carousel.carousel-slider .control-arrow {
  top: -972px !important;
  color: #000 !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000 !important;
}

.carousel .carousel-status {
  display: none;
}

.carousel .control-dots {
  display: none;
}

.carousel .slide .legend {
  position: inherit !important;
  opacity: inherit !important;
  margin-left: inherit !important;
  background: transparent;
  bottom: inherit !important;
  left: inherit !important;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  color: rgba(0, 0, 0, 0.8) !important;
}

.label-form-add-event {
  font-family: "Lato";
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  color: #000000b0;
}

.form-control-select-modal {
  border: 0;
  border-bottom: 1px solid #CDCDCD;
  background: url(../../../assets/icons/downI.png) 99% 47% / 13px no-repeat #fff !important;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2em;
  color: #000000;
  padding: 10px;
}

.form-control-input-modal {
  background: #E6F3F0;
  padding: 15px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2em;
  color: #000000;
}

.container-select-disponibilite .checkbox-add-event {
  display: flex;
  justify-content: inherit !important;
  gap: 12px;
  flex-wrap: wrap;
}

.form-control-admin {
  background: #E6F3F0;
  padding: 15px;
}

.admin-custom-showHideIcon {
  background: #E6F3F0;
  color: #099876;
}

.carousel-control-next,
.carousel-control-prev {
  top: -13px !important;
  bottom: inherit !important;
  display: block !important;
}

.carousel-control-prev {
  left: 25% !important;
}

.carousel-control-next {
  right: 25%;
}

.carousel-control-next svg,
.carousel-control-prev svg {
  color: #000;
  font-size: 35px;
}

.sigCanvas-container {
  margin: 0 auto;
  height: 300px;
}

.sigCanvas {
  /* padding: 0.2rem 15px; */
  background: rgb(238, 240, 244);
  border: 1px dashed rgb(104, 213, 196);
  border-radius: 12px;
  /* width: 90%;
  height: 190px; */
  /* width: 100%;
  height: 100%; */
}



.btn-signature-content {
  display: flex;
  justify-content: center;
  gap: 5px;
  position: absolute;
  right: -25px;
  top: 0px;
}

.btn-clean,
.btn-clean:disabled {
  color: red;
  border: 0.5px solid red;
  border-radius: 5px;
  opacity: 1;
}

.btn-clean,
svg {
  opacity: 0.8;
}

.trim-btn:hover {
  color: #fff !important;
  background-color: red;
}

.trim-btn,
.trim-btn:disabled {
  color: #30d9c4;
  border: 1px solid #30d9c4;
  border-radius: 10px;
  opacity: 1;
}

.trim-btn:hover {
  color: #fff !important;
  background-color: #30d9c4;
}
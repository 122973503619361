.praticien-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.praticien-card .view-pra-profil-btn {
    padding: 10px 18px;
    border-radius: 10px;
    border: 1px solid rgba(55, 125, 255, 0.20);
    color: var(--primary-default, #099876);
    text-align: center;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 85%;
}

.praticien-card .view-pra-profil-btn:hover {
    background: var(--primary-default, #099876);
    color: #fff;
}

.pra-name {
    color: #545675;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.pra-role {
    color: #9A9BA4;
    text-align: center;
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.row-detail-praticien .detail-pra-card,
.row-detail-praticien .incoming-rdv-card {
    height: 100%;
}

.row-detail-praticien .incoming-rdv-card {
    color: var(--neutral-black, #25282B);
    font-family: Lato;
    font-style: normal;
    line-height: 26px;
    letter-spacing: 0.2px;
}


.row-detail-praticien .rdv-cs-title {
    font-size: 17px;
    font-weight: 700;
}

.row-detail-praticien .rdv-cs-value {
    font-size: 15px;
    font-weight: 500;
}

.row-detail-praticien .rdv-cs-hour {
    font-size: 12px;
    font-weight: 300;
}

.row-detail-praticien .cs-title {
    color: var(--text-color-text-1, #1B212D);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.row-detail-praticien .cs-value {
    color: var(--text-color-text-2, #929EAE);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.row-detail-praticien img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.row-detail-praticien .med-info span {
    display: inline-flex;
    width: 50px;
    height: 50px;
}

.folder-card {
    display: inline-block;
    width: 100%;
    padding: 1rem;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    background: #FFF;
    transition: .4s ease;
    text-decoration: none;
}

.all-folder-container .wrapper:hover .folder-card {
    transform: scale(0.94);
}

.folder-card .custom-card-head .icon {
    color: rgba(0, 0, 0, 0.13);
    font-size: 38px;
}

.folder-card .custom-card-head .name {
    color: #000;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.folder-card .card-separator {
    height: 1px;
    background: #DBDBDB;
}

.folder-card .folder-date-content {
    color: #000;
    font-family: Manrope;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.photo-container {
    /* padding: 2rem 0; */
    display: grid;
    place-items: center;
    margin-bottom: 1rem;
}

.photo-container img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
}

.file-formatter-container img {
    width: 28px;
    height: 28px;
}

.container-title-page-header {
    justify-content: space-between;
}

.container-header-consultation-page {
    display: flex;
    align-items: center;
    gap: 20px;
}

.img-header-consultation {
    width: 71px;
    height: 71px;
    border-radius: 50%;
    object-fit: cover;
}

.title-profil-user-header-consultation {
    color: #25282B;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.2px;
}

.info-date-header-consultationinfo-profil {
    color: #52575C;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.076px;
    letter-spacing: 0.201px;
}

.search-patient-box.with-select-medecin input[type='search'],
.with-select-medecin .css-b62m3t-container {
    width: 50%;
}

.react-select-with-select-medecin__control {
    width: 100%;
    padding: 6px 0.5rem;
    border-radius: 100px !important;
    background: var(--neutral-white, #FFF) !important;
    color: var(--neutral-gray, #A0A4A8) !important;
    font-family: Lato !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    letter-spacing: 0.1px;
    outline: none !important;
    border: 1.2px solid var(--primary-default, #099876) !important;
}
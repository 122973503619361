
.custom-form-container {
    padding: 3rem 1.5rem;
}
  
.custom-form-container .custom-title, .container-title-dash .custom-title {
    color: rgba(0, 0, 0, 0.22);
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28.8px;
    letter-spacing: -0.5px;
    padding-bottom: 2rem;
}
  
.custom-form-container .custom-label {
    color: #5A5A5A;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
  
.c_grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px 90px;
}
  
.custom-input-style {
    background: transparent;
}

form .custom-sm-input {
  position: relative;
}

form .label-and-input-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;
    color: #5A5A5A;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
  
    & label {
      font-weight: 700;
      line-height: normal;
    }
  
    & input {
      outline: none;
      border-color: transparent;
      background-color: transparent;
      border-bottom: 1px solid #E9E9E9;;
      padding: 15px 10px;
      border-radius: 5px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
    }
  
    & span {
      position: absolute;
      top: 18%;
      right: 0;
      margin: auto;
      font-size: 18px;
      color: #5A5A5A;
      padding: 2px 6px;
      border-radius: 50%;
      transition: .3s ease;
      cursor: pointer;
    }
    & span:hover {
      background: #efefef;
    }
}
  
.custom-add-admin-btn {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: var(--grayscale-black, #252733);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.07);
  padding: 12px 18px;
  border: 1.4px solid transparent;
  transition: all .3s ease;
}

.custom-add-admin-btn:hover {
  border-color: #099876;
  color: #099876;
}

.custom-add-admin-btn .add-icon {
  color: #099876;
  font-size: 20px;
}

.custom-form .custom-cancel-btn {
  color: #202020;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.all-permissions-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-auto-flow: row dense;
  gap: 15px 10px;
}

.all-permissions-content .permission-small-card {
  border-radius: 10px;
  border: 2px solid #E1E1E1;
  background: #FFF;
  transition: all .3s ease;
  padding: 13px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.all-permissions-content .permission-small-card label {
  color: #868686;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.all-permissions-content .permission-small-card:is(:hover, :focus, ::selection) {
  border-color: var(--primary-default, #099876);
}

.permission-input {
  accent-color: #099876;
}

.admin-cover-and-name-container img {
  width: 110px;
  height: 110px;
  object-fit: cover;
  border-radius: 50%;
}
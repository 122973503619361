.name-praticien-admin {
    font-family: "Lato";
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    color: #545675;
}

.poste-pratiien-admin {
    font-family: "Lato";
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    color: #9A9BA4;
}

.btn-view-profil-praticien {
    font-family: "Lato";
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    color: #099876;
    padding: 15px;
    border: 1px solid #377dff50;
    border-radius: 10px;
    width: 100%;
}

.btn-view-profil-praticien:hover {
    background: #099876;
    color: #fff;
}

.title-info-perso-praticien {
    font-family: "Lato";
    font-size: 15px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.20000000298023224px;
    color: #A0A4A8;
}

.leader-auto-user-avatar-container {
    position: relative;
}

.leader-auto-user-avatar-edit-hover {
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 16%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.leader-auto-user-avatar-container:hover .leader-auto-user-avatar-edit-hover {
    opacity: 1 !important;
    cursor: pointer;
}

.input-add-avatar {
    display: none;
}

.input-testt {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mon-profil-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 8px solid #099876;
}

.libelle-input-file-pp-praticien {
    font-family: "Roboto";
    font-size: 21px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0px;
    color: #1B1B1B;
}

.leader-auto-user-avatar-edit-hover-deux {
    position: absolute;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    color: #ffffff;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    border-radius: 50%;
}

.input-testt:hover .leader-auto-user-avatar-edit-hover-deux {
    opacity: 1 !important;
    cursor: pointer;
}

.edit-avatar-camera-icon {
    font-size: 26px;
}

.img-profil-praticien {
    width: 100px;
    height: 100px;
    object-fit: fill;
    border-radius: 50%;
}